/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:86e96add-2ed8-4a65-8694-90acc6cd8d60",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_6UU3ojjUq",
    "aws_user_pools_web_client_id": "72or7bhe5uqut9sm6hogdhdn0u",
    "oauth": {
        "domain": "minesight-dev.auth.us-east-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "minesightborer489fc8a0deb24b949d89a80e93dc9b6281655-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
