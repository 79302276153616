import { ConstructFlags, JsonObject } from '@nutrien/minesight-utility-module';

import { getDefaultFeatureFlags } from './getDefaultFeatureFlags';

export interface FeatureFlags {
  allowAutoUpdate: boolean;
  borerBlockUpdate: boolean;
  borerDelaysMultiEditMode: boolean;
  borerDisplayCuttingPermitSeverityValue: boolean;
  borerOutageBanner: {
    message: string;
    display: boolean;
  };
  borerOutageWarningBanner: {
    message: string;
    display: boolean;
  };
  borerRebuildDatbaseButtonEnabled: boolean;
  borerRefreshButtonEnabled: boolean;
  borerWfaiDate: Record<string, string>;
  coryZoneBCuttingPermit: boolean;
  displayrxdbsyncindicator: boolean;
  documentsV2: boolean;
  documentsV2Borer: boolean;
  documentsV2Global: boolean;
  documentsV2Location: boolean;
  passwordlessLogin: boolean;
  maxUpdateVersion: string;
  msBorerDataSync: {
    allowSync: boolean;
    message: string;
  };
  rehabBorersVisible: boolean;
  showCloseShiftButton: boolean;
  syncEnhancements: boolean;
  versionBlacklist: string[];
}

export const constructFlags: ConstructFlags<FeatureFlags> = (settings: JsonObject) => {
  // This ensures that the flags are always set even if we don't get values from ConfigCat.
  const flags: FeatureFlags = {
    ...getDefaultFeatureFlags(),
    ...settings,
  };
  return flags;
};
