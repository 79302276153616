import { FeatureFlags } from './FeatureFlags';

export const defaultFeatureFlags: FeatureFlags = {
  allowAutoUpdate: false,
  borerBlockUpdate: true,
  borerDelaysMultiEditMode: false,
  borerDisplayCuttingPermitSeverityValue: false,
  borerOutageBanner: {
    message: '',
    display: false,
  },
  borerOutageWarningBanner: {
    message: '',
    display: false,
  },
  borerRebuildDatbaseButtonEnabled: true,
  borerRefreshButtonEnabled: true,
  borerWfaiDate: {
    '0fd37374-4add-b21e-fa35-20dab7f8e600': '',
    '6599161d-ab54-289a-0e17-c4c8551540ec': '',
    '843de355-7db7-4692-8d13-d9a7edb7ed2b': '',
    '8b3aa642-48a3-43a6-a346-bab516508700': '',
    '9d900b61-195c-433c-94ee-155a2570d653': '',
  },
  coryZoneBCuttingPermit: false,
  displayrxdbsyncindicator: false,
  documentsV2: false,
  documentsV2Borer: false,
  documentsV2Global: false,
  documentsV2Location: false,
  passwordlessLogin: false,
  maxUpdateVersion: '1.0.0',
  msBorerDataSync: {
    allowSync: true,
    message: '',
  },
  rehabBorersVisible: false,
  showCloseShiftButton: false,
  syncEnhancements: false,
  versionBlacklist: [],
};
