import { useConfigCat } from '@nutrien/minesight-utility-module';

import { featureFlagContext } from './featureFlagContext';
import { constructFlags, FeatureFlags } from './FeatureFlags';
import { getDefaultFeatureFlags } from './getDefaultFeatureFlags';
import { loadConfigCatSdkKeys } from './loadConfigCatSdkKeys';
import { getOptions } from './options';

export interface Props {
  children: React.ReactNode;
}

// All of these values are based on hardcoded values, env vars, and/or local storage settings.
// Thus they are not plugged into the React component lifecycle, which in turn means:
//   1. They're safe to load outside of the component.
//   2. They require a page refresh in order to refresh their values
//     (and thus affect this component and its children).
//   3. They won't cause render loops.
const configCatSdkKeys = loadConfigCatSdkKeys();
const defaultFlags = getDefaultFeatureFlags();
const options = getOptions();

export function ConfigCatFeatureFlags(props: Props) {
  const manager = useConfigCat<FeatureFlags>({
    configCatSdkKeys,
    constructFlags,
    defaultFlags,
    options,
  });
  return (
    <featureFlagContext.Provider value={manager}>{props.children}</featureFlagContext.Provider>
  );
}
