const env = import.meta.env.VITE_REACT_APP_ENV;
interface URLs {
  hazardsRootUrl: string;
  graphUrl: string;
  webSocketGraphUrl: string;
  mTlsUrl: string;
}

// Real Non Dev URLS
const devAPIRootURL = `https://devapi.minesight.nutrien.com`;
const stagingAPIRootURL = `https://stageapi.minesight.nutrien.com`;
const rcAPIRootURL = `https://rcapi.minesight.nutrien.com`;
const uatAPIRootURL = `https://uatapi.minesight.nutrien.com`;
const trainingAPIRootURL = `https://trainingapi.minesight.nutrien.com`;
const prodAPIRootURL = `https://api.minesight.nutrien.com`;

const devGraphUrl = `https://devapi-graph.minesight.nutrien.com/graphql`;
const stageGraphUrl = `https://stageapi.minesight.nutrien.com/graphql`;
const rcGraphUrl = `https://rcapi.minesight.nutrien.com/graphql`;
const uatGraphUrl = `https://uatapi.minesight.nutrien.com/graphql`;
const trainingGraphUrl = `https://trainingapi.minesight.nutrien.com/graphql`;
const prodGraphUrl = `https://api.minesight.nutrien.com/graphql`;

const devWebSocketGraphUrl = `wss://devapi-graph.minesight.nutrien.com/graphql/realtime`;
const stageWebSocketGraphUrl = `wss://stageapi-graph.minesight.nutrien.com/graphql/realtime`;
const rcWebSocketGraphUrl = `wss://rcapi-graph.minesight.nutrien.com/graphql/realtime`;
const uatWebSocketGraphUrl = `wss://uatapi-graph.minesight.nutrien.com/graphql/realtime`;
const trainingWebSocketGraphUrl = `wss://trainingapi-graph.minesight.nutrien.com/graphql/realtime`;
const prodWebSocketGraphUrl = `wss://api-graph.minesight.nutrien.com/graphql/realtime`;

const devMTlsUrl = `https://devapi-mtls.minesight.nutrien.com`;
const stageMTlsUrl = `https://stageapi-mtls.minesight.nutrien.com`;
const rcMTlsUrl = `https://rcapi-mtls.minesight.nutrien.com`;
const uatMTlsUrl = `https://uatapi-mtls.minesight.nutrien.com`;
const trainingMTlsUrl = `https://trainingapi-mtls.minesight.nutrien.com`;
const prodMTlsUrl = `https://api-mtls.minesight.nutrien.com`;

let envUrl;
let graphUrl;
let webSocketGraphUrl;
let mTlsCertUrl;
switch (env) {
  case 'dev':
    envUrl = devAPIRootURL;
    graphUrl = devGraphUrl;
    webSocketGraphUrl = devWebSocketGraphUrl;
    mTlsCertUrl = devMTlsUrl;
    break;
  case 'staging':
    envUrl = stagingAPIRootURL;
    graphUrl = stageGraphUrl;
    webSocketGraphUrl = stageWebSocketGraphUrl;
    mTlsCertUrl = stageMTlsUrl;
    break;
  case 'rc':
    envUrl = rcAPIRootURL;
    graphUrl = rcGraphUrl;
    webSocketGraphUrl = rcWebSocketGraphUrl;
    mTlsCertUrl = rcMTlsUrl;
    break;
  case 'uat':
    envUrl = uatAPIRootURL;
    graphUrl = uatGraphUrl;
    webSocketGraphUrl = uatWebSocketGraphUrl;
    mTlsCertUrl = uatMTlsUrl;
    break;
  case 'training':
    envUrl = trainingAPIRootURL;
    graphUrl = trainingGraphUrl;
    webSocketGraphUrl = trainingWebSocketGraphUrl;
    mTlsCertUrl = trainingMTlsUrl;
    break;
  case 'prod':
    envUrl = prodAPIRootURL;
    graphUrl = prodGraphUrl;
    webSocketGraphUrl = prodWebSocketGraphUrl;
    mTlsCertUrl = prodMTlsUrl;
    break;
  default:
    envUrl = devAPIRootURL;
    graphUrl = devGraphUrl;
    webSocketGraphUrl = devWebSocketGraphUrl;
    mTlsCertUrl = devMTlsUrl;
    break;
}

const apiConfig: URLs = {
  hazardsRootUrl: `${envUrl}`,
  graphUrl: `${graphUrl}`,
  webSocketGraphUrl: `${webSocketGraphUrl}`,
  mTlsUrl: `${mTlsCertUrl}`,
};

export { apiConfig };
