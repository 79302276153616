import axios from 'axios';

import { callAPI } from '@/api/apiUtilities';
import { apiConfig } from '@/config/api.config';
import { MTlsRegistrationResponse, SecretLoginKey } from '@/models/models';

const postApi = async (url: string): Promise<any> => {
  let responseData;
  await axios
    .post(url, {})
    .then(async response => {
      const data = response.data;
      if (data.success) {
        responseData = data;
      } else {
        throw new Error(data.errorMessage);
      }
    })
    .catch(error => {
      if (error.response?.data) {
        responseData = error.response?.data;
      } else {
        throw error;
      }
    });
  return responseData;
};

export const pingTest = async (): Promise<any> => {
  let passedMTls = false;
  await axios
    .get(`${apiConfig.mTlsUrl}/test`, {})
    .then(() => {
      // Handle success
      passedMTls = true;
    })
    .catch(() => {
      // do nothing
      passedMTls = false;
    });

  return passedMTls;
};

export const checkRegistration = async (): Promise<MTlsRegistrationResponse> => {
  return await postApi(`${apiConfig.mTlsUrl}/checkRegistration`);
};

export const GetSecret = async (): Promise<SecretLoginKey> => {
  return await postApi(`${apiConfig.mTlsUrl}/getSecretLoginKey`);
};

export const createRegistration = (borerId: string) =>
  callAPI(`${apiConfig.mTlsUrl}/createRegistration`, {
    method: 'post',
    body: JSON.stringify({
      borerId,
    }),
  });

export const isCurrentUserRegistered = (borerId: string) =>
  callAPI(`${apiConfig.hazardsRootUrl}/checkRegistration`, {
    method: 'POST',
    body: JSON.stringify({
      borerId,
    }),
  });
