import { isJsonObject, parseJson } from '@nutrien/minesight-utility-module';

import { defaultFeatureFlags } from './defaultFeatureFlags';
import { FeatureFlags } from './FeatureFlags';

export const FLAG_CACHE_KEY = 'flagValues';

export function getDefaultFeatureFlags(): FeatureFlags {
  // Check localStorage for cached flag values
  const cachedFlagValues = localStorage.getItem(FLAG_CACHE_KEY);
  if (cachedFlagValues) {
    try {
      const parsedFlagValues = parseJson(cachedFlagValues);
      if (!isJsonObject(parsedFlagValues)) {
        throw new Error(`"${cachedFlagValues}" is not a valid JSON object`);
      }

      return {
        ...defaultFeatureFlags,
        ...parsedFlagValues,
        borerBlockUpdate: true,
        allowAutoUpdate: false,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error parsing cached flag values', e);
    }
  }
  // If no cached values, return default values
  return defaultFeatureFlags;
}
